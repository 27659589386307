import React, { Component } from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'


//require('dotenv').config();



class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      success : false,
      successCount: '',
      url : "", 
      previewImages: [],
      copySuccess: 'Preview',
      uploadType: '',
      completed: 1
    }
  }

  
  /* copies the image URL to clipboard */
  copyToClipboard = (e) => {
        e.preventDefault();
        e.target.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        this.setState({ copySuccess: 'Copied!' });
        //set timer to hide message in 3 seconds
        setTimeout(() => this.setState({ copySuccess: 'Preview' }), 3000);
  };


  
  handleSubmit = (e) => {
    //no longer being used
  }



  /* sets designId to state when entered by user (upload) */
  handleChangeDesignId = (ev) => {
    this.setState({designId: ev.target.value});
  }
  


  /* sets image name to state when entered by user (upload) */
  handleChangeName = (ev) => {
    this.setState({imageName: ev.target.value});
  }
  


  handleChange = (ev) => {
    //no longer being used
    //this.setState({success: false, url : ""});
  }

  handleUploadTypeChange = (e) => {
        let myDesignId = ''
        if(e.target.value === 'other'){
            myDesignId = 'general-upload'
        }
        this.setState({designId: myDesignId, uploadType: e.target.value})
        
  }


  /* This is the main function that will handle the file(s) upload to S3.  The files are uploaded to the snowdam-stg folder. */
  handleUpload = (ev) => {

    //set initial states to show upload progress
    this.setState({completed: 1});
    this.setState({success: false});
    this.setState({imgcount: ev.length});
    this.setState({progress: true});

    
    const originalOrDuplicateImage = async (ev) =>{
        let myDesignId = this.state.designId;
        let myImageName = this.state.imageName;
        var siteUrl = ''; //set siteUrl if declared (for local testing)
        if(process.env.REACT_APP_API_HOST){
            siteUrl = process.env.REACT_APP_API_HOST;
        }
        let evArray = []

        for(let i = 0; i < ev.length; i++){
            let countImg = +i + 1
            let file = ev[i];
            let myContinue = true
            //v2.0.1 if this is an automated design_id upload we need to verify that there is a design_id in the file name before we continue
            //we should also store the design_id to use below
            if(this.state.uploadType === 'catalog_multiple'){
                let findUnderscore = ev[i].name.indexOf("_")
                if(findUnderscore > -1){
                    //we found the underscore.  get the design_id
                    myDesignId = ev[i].name.substr(0,findUnderscore)
                    myImageName = ev[i].name.replace(/[^a-zA-Z0-9._-]/g, "")
                }else{
                    myContinue = false
                    this.props.showAlert('error', 'no design_id found', '', 5000)
                }
            }
            if(myContinue){
                let subDir = ''
                if(this.state.uploadType === 'swatch'){
                    subDir = 'swatch/'
                    myImageName = 'swatch'
                    myDesignId = 'general-upload'
                }
                //get the file name and type
                let fileName = subDir + ev[i].name.replace(/[^a-zA-Z0-9._-]/g, "");
                let fileType = ev[i].type;

                
                let moreOptions = {
                    'fileName': fileName,
                    'fileType': fileType

                };
                var checkResult = await axios.post(siteUrl + '/api/originalOrDuplicate', moreOptions, { withCredentials: true })
                // alert user of duplicate 
                if(checkResult && checkResult.data && checkResult.data.error){
                    // if user selects ok push to evArray for save
                    if(window.confirm(checkResult.data.error)){
                        // finishTheSave(ev)
                        evArray.push(ev[i])
                    } 
                } else {
                    // original image, finish save
                    evArray.push(ev[i])
                    // finishTheSave(ev)
                }
            }
            if(i === ev.length - 1 && myContinue === true ){
                finishTheSave(evArray)
            }

        } //end for loop


    }
    originalOrDuplicateImage(ev)


    const finishTheSave = async (ev) => {
    //async function finishTheSave(ev){
        //declare other init variables
        var myParams = [];
        var today = new Date();
        var dateTime = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate() + ' ' + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let myDesignId = this.state.designId;
        let myImageName = this.state.imageName;
        var siteUrl = ''; //set siteUrl if declared (for local testing)
        if(process.env.REACT_APP_API_HOST){
            siteUrl = process.env.REACT_APP_API_HOST;
        }
        for(let i = 0; i < ev.length; i++){
            let countImg = +i + 1
            let file = ev[i];
            let myContinue = true
            //v2.0.1 if this is an automated design_id upload we need to verify that there is a design_id in the file name before we continue
            //we should also store the design_id to use below
            if(this.state.uploadType === 'catalog_multiple'){
                let findUnderscore = ev[i].name.indexOf("_")
                if(findUnderscore > -1){
                    //we found the underscore.  get the design_id
                    myDesignId = ev[i].name.substr(0,findUnderscore)
                    myImageName = ev[i].name.replace(/[^a-zA-Z0-9._-]/g, "")
                }else{
                    myContinue = false
                }
            }
            if(myContinue){
                let subDir = ''
                if(this.state.uploadType === 'swatch'){
                    subDir = 'swatch/'
                    myImageName = 'swatch'
                    myDesignId = 'general-upload'
                }
                //get the file name and type
                let fileName = subDir + ev[i].name.replace(/[^a-zA-Z0-9._-]/g, "")
                let fileType = ev[i].type;

                
                let moreOptions = {
                    'fileName': fileName,
                    'fileType': fileType

                };
                //get a signed url to upload this file to the DAM
                var result = await axios.post(siteUrl + '/api/signImage', moreOptions, { withCredentials: true })
                    
                if(result.data.status !== 200){
                    //user does not have permission to upload file.  alert user and reset state
                    this.props.showAlert('error', result.data.error, 5000, '')
                    this.setState({progress: false});
                    this.setState({success: true});
                    this.setState({designId: false});
                    this.setState({imageName: false});
                }else{
                    this.setState({url: result.data.url});
                    //add the content type to the upload header - adds content-type metadata
                    var options = {
                        headers: {
                            'Content-Type': fileType
                        }
                    }
                    //upload the file using the signed URL
                    var result2 = await axios.put(result.data.signedRequest,file,options)
                        /*  .then(result => { */
                        //add image record to the myParams array (will be used to save records to the BQ table)
                        let imageUser = this.props.smUser
                        if(!imageUser){
                            imageUser = 'unknown.user'
                        }
                        // v2.0.1 if this is an automated design id file, we need to extract it from the name
                        myParams.push({ image_file_name: fileName, image_design_id: myDesignId, image_name: myImageName, image_datetime: dateTime, image_bucket: result2.data.s3_bucket, image_key: fileName, image_tags: imageUser });
                        //if(countImg === this.state.imgcount){
                        countImg++;
                        if(i === ev.length-1){
                            //this is the final image, hide progress and show success
                            //this is the last item being uploaded - all image records have been added to the array.  save the records.
                            let moreOptions = {
                                'image_files': myParams
                            }
                            var saveTheRecords = await axios.post(siteUrl + '/api/saveImage', moreOptions, { withCredentials: true })
                            //this.setState({completed: countImg});
                            this.setState({progress: false});
                            this.setState({success: true});
                            this.setState({designId: false});
                            this.setState({imageName: false});
                            this.setState({successCount: ev.length})
                            this.setState({uploadType: ''})
                            countImg = 1
                            
                        }
                        
                        //show the new image in the preview pane
                        //add result.data.url image tag to preview pane
                        let myImages = this.state.previewImages
                        myImages.push(fileName)
                        this.setState({previewImages: myImages.reverse()})


                        //set the current image count displayed to user
                        
                        this.setState({completed: countImg});
                    /* })
                    .catch(error => {
                        console.log("ERROR " + JSON.stringify(error));
                        
                    }) */
                } //end if user is verified
            }else{ //else if myContinue is false
                let myImages = this.state.previewImages
                myImages.push('Error: Missing Design ID in File Name [' + ev[i].name + ']')
                this.setState({previewImages: myImages.reverse()})
                countImg++;
                
                if(i === ev.length-1){
                    //this is the last item being uploaded - all image records have been added to the array.  save the records.
                    let moreOptions = {
                    'image_files': myParams
                    }
                    await axios.post(siteUrl + '/api/saveImage', moreOptions, { withCredentials: true })
                    this.setState({progress: false});
                    this.setState({success: true});
                    this.setState({designId: false});
                    this.setState({imageName: false});
                    this.setState({successCount: ev.length})
                    this.setState({uploadType: ''})
                    this.setState({completed: countImg});
                    countImg = 1
                    
                }
                this.setState({completed: countImg});
                
            }


        } //end for loop
    } //end async function
    // finishTheSave(ev)




  }



  /* set the progress state */
  onUploadProgress(percent){
    this.setState({completed: percent});
  }

  



  render() {
    const Success_message = () => (
        <div>
            <h3 style={{color: '#00aeef', fontSize: '22px'}}>
                Your files have been uploaded!!<br /><span style={{fontSize: '12px'}}>({this.state.successCount} records saved)</span>
            </h3>  
            
        </div>
    )
    const Progress_bar = () => (
        <h3 style={{color: '#00aeef'}}>Uploading {this.state.completed} of {this.state.imgcount} Files...</h3>    
    )
    return (
        <div className="App"> 
            <div className="content">
                <label>DAM - Upload Images/Files</label><AddAPhotoIcon className="titleIcon" /> 
            </div>
            <div className="twoColWrapper total-card-container" style={{height: '80vh'}}>
            <div className="item">
                <div className="fixedItem">

                    {this.state.success ? <Success_message /> : null}
                    {this.state.progress ? <img alt="preview" className="App-logo-sub" src="./snow-mark.png" /> : 
                        null
                    }
                    {this.state.progress ? <Progress_bar />  : 
                        null
                    }
                    {!this.state.progress ?
                        <div style={{fontSize: '18px', fontWeight: '600', marginBottom: '20px', color: '#013878'}}>
                            {this.state.success ? <div>To upload more images, please select an upload type</div> :  <div>Please select an Upload Type</div>}
                        </div>
                    : null }
                    {!this.state.progress ?
                        <div style={{display: 'flex', width: '100%', marginTop: '20px', flexWrap: 'wrap'}}>
                            <div style={{display: 'flex', width: '50%', marginBottom: '10px'}}><input name="uploadType" type="radio" style={{width: 'auto', cursor: 'pointer'}} value="catalog_multiple" onChange={this.handleUploadTypeChange} /><div style={{marginLeft: '10px'}}>Product Catalog (multipe design id)</div></div>
                            <div style={{display: 'flex', width: '50%', marginBottom: '10px'}}><input name="uploadType" type="radio" style={{width: 'auto', cursor: 'pointer'}} value="other" onChange={this.handleUploadTypeChange}  /><div style={{marginLeft: '10px'}}>Other Image and Video Files</div></div>
                            <div style={{display: 'flex', width: '50%'}}><input name="uploadType" type="radio" style={{width: 'auto', cursor: 'pointer'}} value="catalog" onChange={this.handleUploadTypeChange} /><div style={{marginLeft: '10px'}}>Product Catalog (single design id)</div></div>
                            <div style={{display: 'flex', width: '50%'}}><input name="uploadType" type="radio" style={{width: 'auto', cursor: 'pointer'}} value="swatch" onChange={this.handleUploadTypeChange}  /><div style={{marginLeft: '10px'}}>Swatch</div></div>
                        </div>
                    : null }


                    {this.state.progress || !this.state.uploadType ? 
                        null 
                    
                    : this.state.uploadType === 'catalog' ?
                        <div style={{fontSize: '18px', fontWeight: '600', marginTop: '20px', color: '#013878'}}>Please enter a <i>design id</i> and <i>image name</i> to begin uploading image(s).<br /><br />
                        </div>
                    : this.state.uploadType === 'catalog_multiple' ?
                        <div style={{fontSize: '18px', fontWeight: '600', marginTop: '20px', color: '#013878'}}>The <i>design id</i> will be extracted from the file name of each image.  If any of your image file names are not formatted correctly, they will be rejected.<br /><br />
                        </div>
                    : this.state.uploadType === 'swatch' ?
                        <div style={{fontSize: '18px', fontWeight: '600', marginTop: '20px', color: '#013878'}}>Please select swatch files to upload.  Your images will be uploaded to the swatch folder and will become available on the Shopify frontend immediately.<br /><br />
                        </div>
                    :
                        <div style={{fontSize: '18px', fontWeight: '600', marginTop: '20px', color: '#013878'}}>Please enter some <i>tags</i> or <i>keywords</i> to begin uploading image(s).<br /><br />
                        </div>
                    }
                    {this.state.progress || !this.state.uploadType ? 
                        null 
                    :  this.state.uploadType === 'catalog'  ?
                        <div><label>Design ID: &nbsp;
                            <input type="text" value={this.state.value} onChange={this.handleChangeDesignId} />
                        </label>
                        </div>
                    : null }

                    {this.state.progress || !this.state.uploadType ? 
                        null 
                    :  this.state.uploadType === 'catalog'  ?  
                        <div><label>Image Name: &nbsp;
                            <input type="text" value={this.state.value} onChange={this.handleChangeName} />
                        </label>
                        </div>
                    : this.state.uploadType === 'catalog_multiple' || this.state.uploadType === 'swatch' ?
                        null
                    :
                        <div><label>Tags/Keywords: &nbsp;
                            <input type="text" value={this.state.value} onChange={this.handleChangeName} />
                        </label>
                        </div>
                    }
                    {(this.state.designId && this.state.imageName && !this.state.progress) || this.state.uploadType === 'catalog_multiple' || this.state.uploadType === 'swatch' ?
                        <Dropzone onDrop={acceptedFiles => this.handleUpload(acceptedFiles)} onProgress={this.onUploadProgress}>
                        
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p className="dropBox">Drag 'n' drop image files here, or click to select files</p>
                                </div>
                                </section>
                            )}
                        </Dropzone> :
                            <p></p>
                    }
                </div>
            </div>

            <div className="item">
                <div className="damGrid " style={{maxHeight: '70vh', overflow: 'scroll'}}>
                    {this.state.copySuccess === 'Copied!' ? 
                    <div className="copiedAlert">{this.state.copySuccess}</div>
                    : <div className="copiedAlert" style={{color: '#013878', marginBottom: '10px'}}>Upload Preview Pane</div> }
                    <div style={{display: 'flex', flexWrap: 'wrap'}} >
                        {this.state.previewImages.map((item, index) => (
                            <div className="previewPane">
                                {item.split('.').length > 1 ?
                                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                        
                                        {(/[^.]*$/.exec(item)[0] === 'png' || /[^.]*$/.exec(item)[0] === 'jpg' || /[^.]*$/.exec(item)[0] === 'jpeg' || /[^.]*$/.exec(item)[0] === 'gif' || /[^.]*$/.exec(item)[0] === 'svg') && item.substr(0,5) !== 'Error' ?
                                            <img alt="preview" className="previewPaneItem" src={process.env.REACT_APP_CACHE_URL + item} />
                                        :  /[^.]*$/.exec(item)[0] === 'pdf' ?
                                            <PictureAsPdfIcon className="greyHover" style={{marginTop:'10px'}}  /> 
                                        : item.substr(0,5) === 'Error' ?
                                            <div style={{color: 'red', marginLeft: '5px', fontSize: '14px'}}>{item}</div>
                                        : null }


                                        

                                    </div>
                                : <div></div> }

                                {item.substr(0,5) !== 'Error' ? 
                                    <a style={{color: '#2DB7F0'}} href={'https://' + process.env.REACT_APP_BUCKET + '.s3.us-east-2.amazonaws.com/' + item} target="_blank" rel="noopener noreferrer">
                                        {item}
                                    </a>
                                   
                                : null }
                                
                            </div>    
                        ))}
                    </div>

                </div>
            </div>

            </div>
        </div>
    );
  }
}
export default App;